<template>
  <v-form ref="formElem">
    <translation-locales />

    <v-card>
      <v-card-text>
        <v-row>
          <v-col :cols="cols">
            <div class="d-flex align-center">
              <v-select
                v-model="form.scheme_id"
                label="Scheme"
                :items="schemeOptions"
                item-text="title"
                item-value="value"
                outlined
                hide-details
                :rules="[between(form.scheme_id, 1, 99999999)]"
              ></v-select>
            </div>
          </v-col>

          <v-col :cols="cols">
            <div class="d-flex align-center">
              <v-text-field
                v-model="form.value"
                class="mr-2"
                label="Deduction Value"
                hint="deduct on whole checkout, or each specified item"
                :rules="[between(form.value, 1, form.type === 'percentage' ? 100 : 9999999)]"
              ></v-text-field>
              <!-- :suffix="form.type === 'absolute' ? 'hkd' : '%'" -->

              <v-select
                v-model="form.value_type"
                label="Deduction Type"
                :items="valueTypeOptions"
                item-text="title"
                item-value="value"
                outlined
                dense
                hide-details
                style="width: 100px;"
              ></v-select>
            </div>
          </v-col>

          <v-col :cols="cols">
            <v-select
              v-model="form.discountable_id"
              placeholder="Select Category"
              :items="categoriesOptions"
              item-text="title"
              item-value="value"
              label="Categories"
              outlined
              hide-details
              :disabled="!!resource"
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>

// import { TranslatableInput } from '@/components'
import { TranslationLocales } from '@/components'
import { useCurrency } from '@/composables'
import comStore from '@/modules/common/store'
import { t } from '@/plugins/i18n'
import { between, required } from '@core/utils/validation'
import { } from '@mdi/js'
import { computed, ref } from '@vue/composition-api'

export default {
  components: { TranslationLocales },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    cols: {
      type: Number,
      default: 6,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const initialForm = {
      value: props.resource ? props.resource.value : 100,
      scheme_id: props.resource ? props.resource.scheme_id : null,
      value_type: props.resource ? props.resource.value_type : 'absolute',
      discountable_id: props.resource ? props.resource.discountable_id : null,
    }

    const { baseCurrency } = useCurrency()

    const valueTypeOptions = [
      { title: baseCurrency.value.long_symbol, value: 'absolute' },
      { title: '%', value: 'percentage' },
    ]

    const schemeOptions = comStore.state.discountSchemes.map(scheme => ({
      title: t(scheme.name),
      value: scheme.id,
    }))

    const categoriesOptions = computed(() => comStore.state.categories.map(cat => ({ title: `${t(cat.name)} (${cat.code})`, value: cat.id })))

    const form = ref({ ...initialForm })

    const formElem = ref(null)

    const validate = () => {
      const data = { ...form.value }

      if (formElem.value.validate()) emit('submit', data)
    }

    return {
      form,
      formElem,
      validate,
      required,
      between,

      valueTypeOptions,
      schemeOptions,
      categoriesOptions,

      baseCurrency,
    }
  },
}
</script>
